import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Compgoal from "./Compgoal";
import Compassist from "./Compassist";
import Compusers from "../History/Compusers";
import { Link } from "react-router-dom";
import Compkartu from "./Compkartu";
import Compjadwalranksolo from "./Compjadwalranksolo";

export default function Compmainhasilrank() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [hasil, setHasil] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Hasilranksolo");
      setHasil(response.data);
    } catch (error) { }
  };

  const resvonsive = {
    0: {
      items: 1.5,
    },
    600: {
      items: 1.5,
    },
    1000: {
      items: 1.5,
    },
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <br></br>
      <div className=" container mt-4">
        <br></br>
        <OwlCarousel
          className="owl-theme"
          loop
          margin={4}
          nav={false}
          responsive={resvonsive}
          dotsEach
          autoplay
        >
          <img
            src="/img/bennerbooking.png"
            className="img-fluid"
            alt=""
            style={{ borderRadius: "5px" }}
          ></img>
          <img
            src="/img/dp.png"
            className="img-fluid"
            alt=""
            style={{ borderRadius: "5px" }}
          ></img>
        </OwlCarousel>

        <div className="card mb-4 shadow" style={{ border: "none" }}>
          <div className="card-body">
            <h5 className="fw-bold" style={{ color: "#2b2e5a" }}>
              Hasil rank solo
            </h5>
            <p>Hasil rank solo medan mini soccer</p>
          </div>
        </div>
        {hasil != false || hasil != null || hasil != "" ? (
          <>
            {hasil.map((data, index) => {
              return (
                <div className="mt-3" key={index}>
                  <div
                    className={
                      data.team == "Hitam" || data.team == "Kuning"
                        ? "d-none"
                        : ""
                    }
                  >
                    <Compjadwalranksolo kode_main={data.kode_main} />
                  </div>
                  <div className="card" id="bgcardliga">
                    <div className="card-body">
                      <div
                        className="card"
                        style={{
                          border: "3px solid white",
                          backgroundColor: "#2b2e5a",
                        }}
                      >
                        <div class="row mt-3">
                          <div className="col-sm-5 col-5">
                            <center>
                              <img
                                src={
                                  data.team == "Merah"
                                    ? "/img/merah.png"
                                    : data.team == "Kuning"
                                      ? "/img/kuning.png"
                                      : data.team == "Hitam"
                                        ? "/img/hitam.png"
                                        : "/img/putih.png"
                                }
                                className="img-fluid"
                                alt=""
                                style={{ borderRadius: "5px", height: "80px" }}
                              ></img>
                              <Compusers />
                            </center>
                            <div className="d-flex justify-content-center container">
                              <div>
                                <Compgoal
                                  kode_main={data.kode_main}
                                  team={data.team}
                                />
                              </div>
                              <div style={{ marginLeft: "5px" }}>
                                <Compassist
                                  kode_main={data.kode_main}
                                  team={data.team}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-start container">
                              <Compkartu
                                kode_main={data.kode_main}
                                team={data.team}
                              />
                            </div>
                          </div>
                          <div
                            className="mt-3 text-center col-sm-2 col-2"
                            style={{ color: "#ffff" }}
                          >
                            <Link
                              to={
                                "/pemainmemberkarir/" +
                                data.kode_main +
                                "/" +
                                data.team +
                                "/" +
                                data.team_lawan
                              }
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <img
                                src="./img/logomms.png"
                                className="img-fluid"
                                style={{ height: "30px" }}
                              ></img>
                              <p
                                className="fw-bold text-white"
                                style={{ fontSize: "10px" }}
                              >
                                {data.score_team}{" "}
                                <lalbel className="text-white"> - </lalbel>{" "}
                                {data.score_lawan}
                              </p>

                              <img
                                src="./img/play.png"
                                className="img-fluid"
                                style={{ height: "30px" }}
                              ></img>
                            </Link>
                          </div>
                          <div className="col-sm-5 col-5">
                            <center>
                              <img
                                src={
                                  data.team_lawan == "Merah"
                                    ? "/img/merah.png"
                                    : data.team_lawan == "Kuning"
                                      ? "/img/kuning.png"
                                      : data.team_lawan == "Hitam"
                                        ? "/img/hitam.png"
                                        : "/img/putih.png"
                                }
                                className="img-fluid"
                                alt=""
                                style={{ borderRadius: "5px", height: "80px" }}
                              ></img>
                              <Compusers />
                            </center>
                            <div className="d-flex justify-content-center container">
                              <div>
                                <Compgoal
                                  kode_main={data.kode_main}
                                  team={data.team_lawan}
                                />
                              </div>
                              <div style={{ marginLeft: "3px" }}>
                                <Compassist
                                  kode_main={data.kode_main}
                                  team={data.team_lawan}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-end container">
                              <Compkartu
                                kode_main={data.kode_main}
                                team={data.team_lawan}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <hr
                    className={
                      data.team_lawan == "Kuning" || data.team_lawan == "Merah"
                        ? ""
                        : "d-none"
                    }
                  /> */}
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className="card mb-4 shadow" style={{ border: "none" }}>
              <div className="card-body">
                <div>
                  <center>
                    <img
                      src="/img/bin.png"
                      className="img-fluid"
                      alt=""
                      style={{
                        borderRadius: "5px",
                        height: "100px",
                      }}
                    ></img>
                    <h4 className="fw-bold text-success mt-2">
                      Hello {localStorage.getItem("nama")}
                    </h4>
                    <p
                      className="text-secondary"
                      style={{ marginBottom: "200px" }}
                    >
                      Untuk saat ini hasil rank solo medan mini soccer belum
                      tersedia
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="mb-5"></div>
    </div>
  );
}
