import React, { useState } from 'react';
import { getStorage, ref, deleteObject } from 'firebase/storage';

import { imageDb } from '../firebase';


const Hapusbukti = () => {
  const [imagePath, setImagePath] = useState(""); // Path gambar di Firebase Storage

  // Fungsi untuk menghapus gambar
  const deleteImage = async () => {
    try {
      // Membuat referensi file di Firebase Storage berdasarkan path yang diberikan
      const imageRef = ref(imageDb, imagePath);

      // Menghapus file dari Firebase Storage
      await deleteObject(imageRef);

      // Memberikan pemberitahuan berhasil menghapus gambar
      alert("Gambar berhasil dihapus!");
    } catch (error) {
      console.error("Error menghapus gambar:", error);
      alert("Gagal menghapus gambar.");
    }
  };

  return (
    <div>
      <h3>Hapus Gambar dari Firebase</h3>
      <input
        type="text"
        placeholder="Masukkan path gambar (misal: folder/namafile.jpg)"
        value={imagePath}
        onChange={(e) => setImagePath(e.target.value)}
      />
      <button onClick={deleteImage}>Hapus Gambar</button>
    </div>
  );
};

export default Hapusbukti;
