import React, { useState } from 'react'

export default function Syarat() {
  const [tampil, setTampil] = useState(false);

  const handlesyarat = (val) => {
    if (val == 'tampil') {
      setTampil(true);
    } else {
      setTampil(false)
    }
  }
  return (
    <div>
      <div class="d-flex justify-content-between">
        <label className='fw-bold mb-3'>Syarat dan ketentuan</label>
        <label className='fw-bold'><i className='fas fa-angle-right'></i></label>
      </div>
      <div className='card mb-3'>
        <div className='card-body text-secondary'>
          Untuk mendapatkan voucher gratis booking lapangna medan mini soccer anda harus memenuhi syarat dan ketentuan sebagai berikut :

          {tampil == true ? <><ul>
            <li>Mempunyai akun medan mini soccer (Tidak menggunakan mode tamu) </li>
            <li>Mempunyai 20 Jam booking lapangan mulai dari bulan Oktober 2024 </li>
            <li>Berlaku mulai dari bulan Oktober 2024</li>
            <li>Status booking lapangan harus disetujui</li>
            <li>Total pembayaran kurang dari 10 Juta Free Lapangan Blue Dragon </li>
            <li>Total pembayaran 10 Juta s/d kurang dari 13 Juta Free Lapangan Red Tiger</li>
            <li>Total pembayaran sama dengan 13 Juta atau lebih dari 13 Juta Free Lapangan White Rat </li>
          </ul>
            <div className='text-primary' onClick={() => handlesyarat('tutup')} style={{ cursor: 'pointer' }}>Tutup syarat dan ketentuan ?</div>
          </> : <><div className='text-primary' onClick={() => handlesyarat('tampil')} style={{ cursor: 'pointer' }}>Lihat lebih banyak ?</div></>}

        </div>
      </div>
    </div>
  )
}
