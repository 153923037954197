import React from 'react'
import Navbar from '../componenst/Navbar'
import Listvoucher from '../componenst/Voucher/Listvoucher'
import Syarat from '../componenst/Voucher/Syarat'

export default function Voucher() {
    return (
        <div>
            <Navbar judul="Voucher" aicon='true' />
            <div className='card shadow' style={{ borderRadius: '0px', border: '0px' }}>
                <div className='card-body'>
                    {/* <div className='card mt-5'>
                      <div className='card-body'>
                          <h5>Hello {localStorage.getItem('nama')}</h5>
                          <small>Voucher yang anda dapat, dapat anda tukar melalui admin kami</small>
                      </div>
                 </div> */}

                    <img src="/img/vc.webp" className="img-fluid mt-5" alt="..." style={{ borderRadius: '10px' }}></img>
                    <hr />
                    <Syarat />
                    <Listvoucher />
                </div>
            </div>
        </div>
    )
}
